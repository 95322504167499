$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-profile {
  text-align: center;
  max-width: rem(250px);

  @include media('<screenSmall') {
    margin: 0 auto;
  }

  @include media('>=screenSmall') {
    text-align: left;
  }

  .sw-profile__image {
    width: 100%;
  }

  .sw-profile__name {
    @include heading5;
    margin: (map-get($grid, row-gap)) 0 (map-get($grid, row-gap) / 2) 0;

    .sw-icon {
      height: 0.75rem;
      width: 0.75rem;
    }

    :hover .sw-icon {
      @include right-nudge;
    }

    .sw-profile__name__link {
      color: map-get($colors, dark-text);
      text-decoration: none;
    }
  }
}
